<div class="container">
    <div class="filter">
        <mat-form-field appearance="standard">
            <mat-label class="concept">{{ 'PAGE.ACTIONS.CONCEPT' | translate }}</mat-label>
            <mat-select (selectionChange)="obtainSubfilters($event.value)" (selectionChange)="obtainIdSelect($event)">
                <mat-option *ngFor="let concepto of conceptosMaestro" [value]="concepto">
                    {{ concepto.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="subfilter" *ngFor="let filtro of subfilters; let i = index">
        <div *ngIf="validRole">
            <app-filtro-date
                *ngIf="filtro.type === 'Date' && showSelect"
                [name]="filtro.name"
                (select)="receiveDate($event, i)"
                (invalidDate)="isInvalidDate($event)"
            ></app-filtro-date>
            <app-filtro-select
                #selects
                *ngIf="
                    filtro.type === 'Select' &&
                    checkConditions(filtro.displayConditions) &&
                    showSelect &&
                    filtro.key != 'programUnitt'
                "
                [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                [toggleLabelRight]="filtro.isToggle?.rightLabel"
                (searchBy)="getFiltersBy($event)"
                [charactersControl]="filtro.isSearchable?.specialSearch"
                [searchByToggle]="filtro.isToggle?.active"
                [isSearchable]="filtro.isSearchable?.active"
                [filtroUrl]="filtro.data"
                [title]="filtro.name"
                [key]="filtro.key"
                [id]="filtro.id"
                (valueChange)="obtainOption($event)"
                (specialSearch)="specialSearch($event)"
                (openSelect)="byOpenSelect($event)"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                (selectSelected)="checkValueSelects($event)"
                [title]="filtro.name"
                [screen]="selectId"
                [theFirstTime]="isFirstTime"
                [placeholderLabel]="filtro?.isSearchable?.specialSearch ? (placeholder$ | async) : filtro.name"
                [specialFeeActivated]="specialFeeActivated"
            ></app-filtro-select>
            <ng-container *ngIf="filtro.type === 'MultiSelect' && filtro.key === 'status' && checkConditions(filtro.displayConditions) && showSelect">
                <app-filtro-multiselect
                    [filtroUrl]="filtro.data"
                    [title]="filtro.name"
                    [key]="filtro.key"
                    (valueChange)="filterContract($event)"
                ></app-filtro-multiselect>
            </ng-container>
            <app-filtro-input
                *ngIf="filtro.type === 'Input' && checkConditions(filtro.displayConditions) && showSelect"
                [name]="filtro.name"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
            >
            </app-filtro-input>
            <app-filtro-textarea *ngIf="filtro.type === 'Textarea'"></app-filtro-textarea>
            <app-filtro-cups
                *ngIf="filtro.type === 'cups'"
                [filtroUrl]="filtro.data"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                (resetComplete)="resetComplete($event)"
                [name]="filtro.name"
                [reset]="resetCups"
            ></app-filtro-cups>
            <app-filtro-year
                *ngIf="filtro.type === 'Year' && showSelect"
                [value]="yearValue"
                [title]="filtro.name"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                [id]="filtro.id"
            ></app-filtro-year>
            <app-filtro-select
                #selects
                *ngIf="filtro.key === 'programUnitt' && checkConditions(filtro.displayConditions) && showSelect"
                [toggleLabelLeft]="filtro.isToggle?.leftLabel"
                [toggleLabelRight]="filtro.isToggle?.rightLabel"
                (searchBy)="getFiltersBy($event)"
                [charactersControl]="filtro.isSearchable?.specialSearch"
                [searchByToggle]="filtro.isToggle?.active"
                [isSearchable]="filtro.isSearchable?.active"
                [filtroUrl]="unitProgramList2"
                [title]="filtro?.isSearchable?.specialSearch ? (placeholder$ | async) : filtro.name"
                [key]="filtro.key"
                [id]="filtro.id"
                (valueChange)="obtainOption($event)"
                [selectedNameChange]="selectedName"
                (openSelect)="byOpenSelect($event)"
                (select)="restarValue($event, i, filtro.key, filtro.type)"
                (selectSelected)="checkValueSelects($event)"
                [specialFeeActivated]="specialFeeActivated"
            ></app-filtro-select>
            <div *ngIf="filtro.type === 'SelectMonth'" [formGroup]="formGroup">
                <mat-form-field
                    appearance="fill"
                    [ngClass]="{ meses: subfilters[0].selected === '', mesesSelected: subfilters[0].selected !== '' }"
                >
                    <mat-label>{{ 'PAGE.FILTERS.SELECT.MONTH' | translate }}</mat-label>
                    <mat-select
                        #matSelectMonth
                        formControlName="month"
                        (selectionChange)="restarValue($event, 0, filtro.key, filtro.type)"
                    >
                        <mat-option *ngFor="let month of months" [value]="month">
                            {{ monthDisplay(month) | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<!-- PANTALLA QUE SE MUESTRA SI USUARIO NO TIENE ROLES ADECUADOS -->
<div *ngIf="!validRole">
    <h3 class="invalidRol">{{ 'PAGE.PERMISSIONS.INVALID_ROLE' | translate }}</h3>
</div>
<div *ngIf="validRole">
    <div class="buttonsFilter">
        <div>
            <button class="delete" (click)="clearFilters()" (mouseup)="hideSelect()" mat-button>
                {{ 'PAGE.ACTIONS.DELETE' | translate }}
                <app-base-icon iconName="filter_alt_off" class=""></app-base-icon>
            </button>
            <button class="search" mat-flat-button color="warn" (click)="loadTable()" [disabled]="isDisabled">
                {{ 'PAGE.ACTIONS.FILTER' | translate | uppercase }}<app-base-icon iconName="search"> </app-base-icon>
            </button>
        </div>
    </div>
</div>
