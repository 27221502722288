import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { ExportService } from 'src/app/services/export.service';
import { DownloadExcelComponent } from 'src/app/shared/components/download-excel/download-excel.component';
import { ClosedStatus, ClosedStatusFilter, CoberturasContratosStatus } from 'src/app/shared/enums/contractEnums';
import { Months } from 'src/app/shared/components/custom-cron/custom-cron.enum';
import { ReturnVariableTypes, VariableTypes } from 'src/app/shared/enums/variableTypesContractPrices';
import { AutonomiesBackResponse } from 'src/app/shared/models/autonomias';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { Tabla } from 'src/app/shared/models/files.interface';
import { Permisos } from 'src/app/shared/models/permisos.interface';
import { AutonomyCodes } from '../../../../shared/enums/autonomyCodes';
import { MaestrosAWS, MaestrosCRM, PuntoFronteraTypeModal } from '../../../../shared/enums/maestros';
import { MaestroService } from '../../services/maestro.service';
import { AddModalComponent } from './add-modal/add-modal.component';
import { BoundaryPointsModalComponent } from './boundary-points-modal/boundary-points-modal.component';
import { ClaimDetailsPageComponent } from './claim-details-page/claim-details-page.component';
import { DeleteModalComponent } from './delete-modal/delete-modal-component';
import { DetailsPageComponent } from './details-page/details-page.component';
import { EditModalComponent } from './edit-modal/edit-modal.component';
import { ShowModalComponent } from './show-modal/show-modal.component';
import { HeaderTitleService } from 'src/app/services/header-title.service';

@Component({
    selector: 'app-tabla-maestro',
    templateUrl: './tabla-maestro.component.html',
    styleUrls: ['./tabla-maestro.component.scss'],
})
export class TablaMaestroComponent implements OnInit, AfterViewInit {
    @Input() dataBusiness: any[];
    @Input() dataHeaders: IColumnsTable[] = [];
    @Input() dataHeaders2: IColumnsTable[] = [];
    @Input() dataHeaders_valueSelect: IColumnsTable[] = [];

    @Input() columns: any[];

    @Input() totalRegisters: number;
    @Input() totalPages: number;
    @Input() numPage: number;
    @Input() pageSize: number;
    pageSizeOptions: number[] = [10, 25, 50, 100];

    @Output() sort: EventEmitter<any> = new EventEmitter();
    @Output() refreshAfterEdit: EventEmitter<void> = new EventEmitter<void>();
    @Output() pagination: EventEmitter<any> = new EventEmitter();
    @Input() permissions?: Permisos;
    @Input() filtersTable: any[];
    @Input() idFilters: any;
    @Input() regions?: any[] = [];

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    dataSource: any;
    numPagePaginator: number;
    selectId: string;

    get VariableTypes() {
        return VariableTypes;
    }

    get ReturnVariableTypes() {
        return ReturnVariableTypes;
    }

    get MaestrosCRM() {
        return MaestrosCRM;
    }

    get PuntoFronteraTypeModal() {
        return PuntoFronteraTypeModal;
    }

    @Input() filters: any;
    // input con filtros

    @ViewChild(MatTable) table: MatTable<any>;
    @ViewChild('paginator') paginator: MatPaginator;
    paginatorDataSource: MatTableDataSource<any>;

    displayedColumns: any;
    infotabla: string[];
    dataShow: Tabla[] = [];
    message: string = '';
    action: string = '';
    config: any;
    element = false;
    ocultar = true;
    dataHeaderChange = true;
    hour: boolean = true;

    dataNavigation: NavigationExtras = {
        state: {
            contractCode: '',
        },
    };

    constructor(
        public dialogRef: MatDialogRef<DownloadExcelComponent>,
        public dialog: MatDialog,
        public maestroService: MaestroService,
        public DeleteModalComponent: MatDialog,
        public EditModalComponent: MatDialog,
        public DetailsPageComponent: MatDialog,
        public AddModalComponent: MatDialog,
        public ShowModalComponent: MatDialog,
        public BoundaryPointsModalComponent: MatDialog,
        public readonly _headerTitleService: HeaderTitleService,
        private exportService: ExportService,
        private _snackBar: MatSnackBar,
        private _translateService: TranslateService
    ) {}

    get MaestrosAWS() {
        return MaestrosAWS;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource<any>(this.dataBusiness);
        this.message = this._translateService.instant('PAGE.MODALS.LOADING_MESSAGE_EXCEL');
        this.action = this._translateService.instant('PAGE.MODALS.CLOSE');
        this.selectId = this.maestroService.selectId;
    }

    ngAfterViewInit(): void {
        this.paginatorDataSource = new MatTableDataSource(this.dataBusiness);
        this.paginatorDataSource.paginator = this.paginator;
    }

    pageChange(event: any) {
        this.pagination.emit(event);
    }

    sortData(sort: Sort) {
        this.sort.emit({ sort: sort, id: this.idFilters[0] });
    }

    openModalExcel() {
        this.exportService.openDialog(this.dataBusiness, this.idFilters, this.totalRegisters);
    }

    delete(pos: any) {
        const dialogRef = this.DeleteModalComponent.open(DeleteModalComponent);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                switch (this.maestroService.selectId) {
                    case MaestrosAWS.FESTIVOS:
                        this.maestroService.deleteFestivo(this.dataBusiness[pos].festiveId).subscribe((dF) => {
                            var idTable = this.dataBusiness[pos].id;
                            this.dataBusiness.splice(pos, 1);
                            this.table.renderRows();
                        });
                        break;
                    case MaestrosAWS.CONTRATOS_FICTICIOS:
                        this.maestroService
                            .deleteContratoFicticio(this.dataBusiness[pos].contractCode)
                            .subscribe((dF) => {
                                var idTable = this.dataBusiness[pos].id;
                                this.dataBusiness.splice(pos, 1);
                                this.table.renderRows();
                            });
                        break;
                    case MaestrosAWS.PRECIOS_REGULADOS:
                        const codesToDelete =
                            this.dataBusiness[pos].code +
                            '/' +
                            this.dataBusiness[pos].rate +
                            '/' +
                            this.dataBusiness[pos].initialDate;
                        this.maestroService.deletePreciosRegulados(codesToDelete).subscribe((dF) => {
                            var idTable = this.dataBusiness[pos].id;
                            this.dataBusiness.splice(pos, 1);
                            this.table.renderRows();
                        });
                        break;
                    case MaestrosAWS.PARAMETROS:
                        this.maestroService.deleteParametros(this.dataBusiness[pos].code).subscribe((dF) => {
                            var idTable = this.dataBusiness[pos].id;
                            this.dataBusiness.splice(pos, 1);
                            this.table.renderRows();
                        });
                        break;
                }
            }
        });
    }

    edit(pos: any) {
        const dialogRef = this.EditModalComponent.open(EditModalComponent, {
            data: {
                header: this.dataHeaders.map((h) => {
                    if (h.id === 'regionCode') {
                        return {
                            ...h,
                            data: this.regions?.map((r: any) => ({ name: r.regionDescription, value: r.regionCode })),
                        };
                    }
                    return h;
                }),
                value: this.dataBusiness[pos],
                filter: this.filters,
                name: this.filtersTable[0],
                regions: this.regions,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.resp) {
                switch (this.filtersTable[0]) {
                    case MaestrosCRM.PUNTO_MEDIDA:
                        this.maestroService
                            .updatePuntoMedida(result.cups, result.cupsMP, result.data)
                            .subscribe((uF) => {
                                this.dataBusiness[pos] = { ...this.dataBusiness[pos], ...result.data };
                                this.table.renderRows();
                            });
                        break;
                    case MaestrosAWS.FESTIVOS:
                        // añadir un 0 en el caso de que no llegue
                        result.data.regionCode = (result?.data?.regionCode?.toString() as string).padStart(2, '0');
                        this.maestroService.updateFestivos(result.festiveId, result.data).subscribe((uF) => {
                            this.dataBusiness[pos] = { ...this.dataBusiness[pos], ...result.data };
                            this.table.renderRows();
                        });
                        break;
                    case MaestrosAWS.PRECIOS_REGULADOS:
                        const NoEditablePR = result.data.code + '/' + result.data.rate + '/' + result.data.initialDate;
                        this.maestroService.updatePreciosRegulados(NoEditablePR, result.data).subscribe((uF) => {
                            this.dataBusiness[pos] = { ...this.dataBusiness[pos], ...result.data };
                            this.table.renderRows();
                        });
                        break;
                    case MaestrosAWS.PUNTO_FRONTERA:
                        this.maestroService
                            .updatePuntoFrontera(
                                result.data,
                                '/' +
                                    this.dataBusiness[pos].boundaryPointCode +
                                    '/' +
                                    this.dataBusiness[pos].initValidityDate
                            )
                            .subscribe((uF) => {
                                this.dataBusiness[pos] = { ...this.dataBusiness[pos], ...result.data };
                                this.table.renderRows();
                            });
                        break;
                    case MaestrosAWS.PARAMETROS:
                        this.maestroService.updateParametros(result.code, result.data).subscribe((uP) => {
                            this.dataBusiness[pos] = { ...this.dataBusiness[pos], ...result.data };
                            this.table.renderRows();
                        });
                        break;
                    default:
                        break;
                }
            }
        });
    }

    add() {
        const dialogRef = this.AddModalComponent.open(AddModalComponent, {
            data: {
                header: this.dataHeaders_valueSelect.map((h) => {
                    if (h.id === 'regionCode') {
                        return {
                            ...h,
                            data: this.regions?.map((r: any) => ({ name: r.regionDescription, value: r.regionCode })),
                        };
                    }
                    return h;
                }),
                value: this.dataBusiness,
                filter: this.filters,
                name: this.filtersTable[0],
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.resp) {
                switch (this.filtersTable[0]) {
                    case MaestrosAWS.FESTIVOS:
                        // añadir un 0 en el caso de que no llegue
                        result.data.regionCode = (result?.data?.regionCode?.toString() as string).padStart(2, '0');
                        this.maestroService.createFestivo(result.data).subscribe((addedFestive) => {
                            this.dataBusiness.push(addedFestive);
                            this.refreshAfterEdit.emit();
                        });
                        break;
                    case MaestrosAWS.PRECIOS_REGULADOS:
                        result.data.code = this.codeConverterPR(result.data.code);
                        this.maestroService.createPreciosRegulados(result.data).subscribe((uF) => {
                            this.dataBusiness.push(result.data);
                            this.refreshAfterEdit.emit();
                        });
                        break;
                    case MaestrosAWS.PARAMETROS:
                        this.maestroService.createParametros(result.data).subscribe((cP) => {
                            this.dataBusiness.push(result.data);
                            this.refreshAfterEdit.emit();
                        });
                        break;
                }
            }
        });
    }

    async showModal(type: PuntoFronteraTypeModal, pos?: any) {
        let search: string = '';
        if (
            this.filtersTable &&
            this.filtersTable.length > 1 &&
            this.idFilters[1].some((filter: any) => filter.selected !== '')
        ) {
            this.filtersTable[1].forEach((filter: any) => {
                if (filter.selected !== '') {
                    if (filter.key === 'boundaryPointCode') {
                        search += `${filter.key}=(${filter.selected}),`;
                    }
                    if (filter.key === 'description') {
                        search += `descriptionlike(${filter.selected})`;
                    }
                }
            });
        }
        // Obtiene las CUPS de las que se pretende modificar sus prelaciones (eliminando valores repetidos)
        const response$ = await firstValueFrom(this.maestroService.getAllCupsToPrecedencesModal());
        const allCups = [
            ...new Set(
                response$.data.map((register: any) => {
                    return register.boundaryPointCode;
                })
            ),
        ];
        // Si supera el límite establecido se mostrará un mensaje para que se refine la búsqueda
        if (allCups.length > 500) {
            this._translateService.get('PAGE.MODALS').subscribe((message) => {
                this._snackBar.open(`${message['CUPS_EXCEDEED_TO_SET_RANKINGS']}`, message['ACTION_CLOSE'], {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                setTimeout(() => {
                    this._snackBar.dismiss();
                }, 7000);
            });
        } else {
            this.maestroService.boundaryPoints = allCups;
            let data;
            if (type === PuntoFronteraTypeModal.MANAGE_PRECEDENCES) {
                data = {
                    header: this.dataHeaders_valueSelect,
                    value: this.dataBusiness[pos],
                    filter: this.filters,
                    name: this.filtersTable[0],
                    type,
                };
            } else {
                data = {
                    header: this.dataHeaders_valueSelect,
                    value: response$.data,
                    filter: this.filters,
                    name: this.filtersTable[0],
                    type,
                };
            }
            const dialogRef = this.ShowModalComponent.open(ShowModalComponent, { data });
        }
    }

    openBoundaryPointsModal(pos: any) {
        const dialogRef = this.BoundaryPointsModalComponent.open(BoundaryPointsModalComponent, {
            data: {
                header: this.dataHeaders_valueSelect,
                value: this.dataBusiness[pos],
                filter: this.filters,
                name: this.filtersTable[0],
                permissions: this.permissions,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.resp) {
            }
        });
    }

    checkAndReturnColumns(element: any, columnId: string) {
        if (
            this.dataBusiness.some(
                (register: any) => register.coefficient && register.coefficient.toString().includes('e-')
            )
        ) {
            this.dataBusiness = this.dataBusiness.map((register: any) => {
                if (register.coefficient.toString().includes('e-')) {
                    register.coefficient = parseFloat(register.coefficient.toString());
                    register.coefficient = register.coefficient.toFixed(register.coefficient.toString().split('e-')[1]);
                }
                return register;
            });
        }
        if (columnId.indexOf('.') > -1) {
            let columnIdSplitted = columnId.split('.');

            return element[columnIdSplitted[0]][columnIdSplitted[1]];
        }
        element.zoneGroup = element.zoneGroup || element.zone || '';
        if (element.zoneGroup.includes('Peninsula')) {
            element.zoneGroup = element.zoneGroup.replace('Peninsula', 'Península');
        }
        if (columnId === 'atrProcess' || columnId === 'atr') {
            return this.maestroService.setBooleanValue(element[columnId]);
        }
        if (columnId === 'realMeasure') {
            this._translateService.get('PAGE.MASTER.MASTER_CRM.CLOSURES').subscribe((message) => {
                if (element[columnId] === 1) {
                    element[columnId] = message.STATUS_ACTIVE;
                } else if (element[columnId] === 0) {
                    element[columnId] = message.STATUS_INACTIVE;
                }
                return element[columnId];
            });
        }
        if (columnId === 'rentMeter') {
            this._translateService.get('PAGE.MASTER.MASTER_CRM.RENT_METER').subscribe((message) => {
                if (element[columnId] === '1') {
                    element[columnId] = message.APPLY;
                } else if (element[columnId] === '3') {
                    element[columnId] = message.NOT_APPLY;
                }
                return element[columnId];
            });
        }
        if (columnId === 'exenType' && element[columnId] !== null) {
            return element[columnId];
        }
        if (columnId === 'clientType') {
            let formatString;
            this._translateService.get('PAGE.MASTER.MASTER_CRM.CLIENT_TYPE').subscribe((str) => {
                switch (element[columnId]) {
                    case '6':
                    case 6:
                        formatString = str.PYMES;
                        break;
                    case '5':
                    case 5:
                        formatString = str.BIG_ACCOUNTS;
                        break;
                    case '4':
                    case 4:
                        formatString = str.BIG_ACCOUNTS;
                        break;
                    case '3':
                    case 3:
                        formatString = str.BIG_ACCOUNTS;
                        break;
                    case '2':
                    case 2:
                        formatString = str.BIG_ACCOUNTS;
                        break;
                    case '1':
                    case 1:
                        formatString = str.BIG_ACCOUNTS;
                        break;
                }
            });
            return formatString;
        }
        if (columnId === 'taxesIndicator' || columnId === 'adjMec') {
            this._translateService.get('PAGE.MODALS').subscribe((message) => {
                if (element[columnId] === '1') {
                    element[columnId] = message.YES;
                } else if (element[columnId] === '0') {
                    element[columnId] = message.NO;
                }
                return element[columnId];
            });
        }

        if (columnId === 'adjustMec') {
            let formatString;
            this._translateService.get('PAGE.DUMMY_CONTRACTS').subscribe((str) => {
                switch (element[columnId]) {
                    case 2:
                        formatString = str.SN;
                        break;
                    case 1:
                        formatString = str.YES;
                        break;
                    case 0:
                        formatString = str.NO;
                        break;
                }
            });
            return formatString;
        }
        if (columnId === 'optionalSettlement' || columnId === 'connectionIssue') {
            this._translateService.get('PAGE.MODALS').subscribe((message) => {
                if (element[columnId] === true) {
                    element[columnId] = message.YES;
                } else if (element[columnId] === false) {
                    element[columnId] = message.NO;
                }
                return element[columnId];
            });
        }
        if (
            columnId === 'numPeriodsEnergy' || //Tarifas AWS
            columnId === 'numPeriodsPower' || //Tarifas AWS
            columnId === 'valueP1' || //Precios regulados AWS
            columnId === 'valueP2' || //Precios regulados AWS
            columnId === 'valueP3' || //Precios regulados AWS
            columnId === 'valueP4' || //Precios regulados AWS
            columnId === 'valueP5' || //Precios regulados AWS
            columnId === 'valueP6' || //Precios regulados AWS
            columnId === 'value' || //Precios indexados AWS
            columnId === 'precedence' || //Prelación AWS
            columnId === 'netMaxPower' || //Unidad de programación AWS
            columnId === 'netMinPower' || //Unidad de programación AWS
            columnId === 'grossMaxPower' || //Unidad de programación AWS
            columnId === 'grossMinPower' || //Unidad de programación AWS
            columnId === 'energy' || //Dist. period. tarifarios AWS
            columnId === 'percentage' || //Liquidación potestativa AWS
            columnId === 'coefficient' || //Coeficiente de pérdidas AWS
            columnId === 'eqRentalPrice' || //Contratos ficticios AWS
            columnId === 'consumptionP1' || //Contratos ficticios AWS
            columnId === 'consumptionP2' || //Contratos ficticios AWS
            columnId === 'consumptionP3' || //Contratos ficticios AWS
            columnId === 'consumptionP4' || //Contratos ficticios AWS
            columnId === 'consumptionP5' || //Contratos ficticios AWS
            columnId === 'consumptionP6' || //Contratos ficticios AWS
            columnId === 'Pw1' || //Contratos ficticios AWS
            columnId === 'Pw2' || //Contratos ficticios AWS
            columnId === 'Pw3' || //Contratos ficticios AWS
            columnId === 'Pw4' || //Contratos ficticios AWS
            columnId === 'Pw5' || //Contratos ficticios AWS
            columnId === 'Pw6' || //Contratos ficticios AWS
            columnId === 'eqRentalPrice' || //Contratos ficticios AWS
            columnId === 'price' || //Variables contratos ficticios AWS
            columnId === 'energy' || //Coberturas de contratos CRM
            columnId === 'fee' || //Coberturas de contratos CRM
            columnId === 'closedMwh' || //Cierres CRM
            columnId === 'closedPriced' || //Cierres CRM
            columnId === 'clousureCost' || //Cierres CRM
            columnId === 'costAgentMargin' || //Cierres CRM
            columnId === 'costComercialMargin' || //Cierres CRM
            columnId === 'priceP1' || //Cierres CRM
            columnId === 'priceP2' || //Cierres CRM
            columnId === 'priceP3' || //Cierres CRM
            columnId === 'priceP4' || //Cierres CRM
            columnId === 'priceP5' || //Cierres CRM
            columnId === 'priceP6' || //Cierres CRM
            columnId === 'rentEquip' || //Punto de medida CRM
            columnId === 'energyP1' || //Consumo anual CRM
            columnId === 'energyP2' || //Consumo anual CRM
            columnId === 'energyP3' || //Consumo anual CRM
            columnId === 'energyP4' || //Consumo anual CRM
            columnId === 'energyP5' || //Consumo anual CRM
            columnId === 'energyP6' || //Consumo anual CRM
            columnId === 'energyTotal' //Consumo anual CRM
        ) {
            return this.checkIfIsNumber(element[columnId]);
        }
        return element[columnId];
    }

    checkBooleanValue(element: any, columnId: string): string {
        let booleanFormatString: string = '';
        if (columnId === 'optionalSettlement' || columnId === 'cecoerActiveHub' || columnId === 'connectionIssue') {
            this._translateService.get('PAGE.MODALS').subscribe((message) => {
                if (element[columnId] === true) {
                    booleanFormatString = message.YES;
                } else if (element[columnId] === false) {
                    booleanFormatString = message.NO;
                }
            });
        }
        return booleanFormatString;
    }

    checkIfIsNumber(isNumber: any) {
        if (typeof isNumber === 'number') {
            const formatedNumber: any = this.formatNumber(isNumber);
            return formatedNumber;
        } else {
            return isNumber;
        }
    }

    protected formatNumber(value: any): string {
        if (value) {
            value = value.toString();
            const isEnglish: boolean = this._headerTitleService.currentLang === 'en';
            const decimalSeparator: '.' | ',' = isEnglish ? '.' : ',';
            const thousandsSeparator: '.' | ',' = isEnglish ? ',' : '.';
            if (value.includes(thousandsSeparator)) {
                value = value.replace(thousandsSeparator, decimalSeparator);
            }
            if (value.includes(decimalSeparator)) {
                const valueParts: any[] = value.split(decimalSeparator);
                const decimalPart = valueParts[1] || '';
                const formattedInteger = valueParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
                return decimalPart ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;
            } else {
                return value.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);
            }
        } else {
            return '_';
        }
    }

    seeDetails(event: any, column: any) {
        if (column) {
            this.maestroService.contractCode = column.contractCode;
            const dialogRef = this.DetailsPageComponent.open(DetailsPageComponent, {
                height: '90%',
            });
            dialogRef.afterClosed().subscribe((result) => {
                result = null;
            });
        }
    }

    seeClaims(element: any) {
        debugger;
        this.maestroService.claimCode = element.boundaryPointCode;
        this.maestroService.objectionCode = '';
        const dialogRef = this.DetailsPageComponent.open(ClaimDetailsPageComponent, {
            height: '90%',
        });
        dialogRef.afterClosed().subscribe((result) => {
            result = null;
        });
    }

    seeObjections(element: any) {
        debugger;
        this.maestroService.claimCode = '';
        this.maestroService.objectionCode = element.boundaryPointCode;
        const dialogRef = this.DetailsPageComponent.open(ClaimDetailsPageComponent, {
            height: '90%',
        });
        dialogRef.afterClosed().subscribe((result) => {
            result = null;
        });
    }

    checkVarType(varType: string): string {
        switch (varType) {
            case VariableTypes.VARIABLE_TYPE_F:
                return ReturnVariableTypes.TYPE_F;
            case VariableTypes.VARIABLE_TYPE_I1:
                return ReturnVariableTypes.TYPE_I1;
            case VariableTypes.VARIABLE_TYPE_I2:
                return ReturnVariableTypes.TYPE_I2;
            case VariableTypes.VARIABLE_TYPE_I3:
                return ReturnVariableTypes.TYPE_I3;
            case VariableTypes.VARIABLE_TYPE_I4:
                return ReturnVariableTypes.TYPE_I4;
            case VariableTypes.VARIABLE_TYPE_I5:
                return ReturnVariableTypes.TYPE_I5;
            case VariableTypes.VARIABLE_TYPE_I6:
                return ReturnVariableTypes.TYPE_I6;
            case VariableTypes.VARIABLE_TYPE_I7:
                return ReturnVariableTypes.TYPE_I7;
            default:
                break;
        }
        return '';
    }

    checkRegion(regionCode: string): string | undefined {
        let regionCodeFormated: string = this.maestroService.checkZeros(regionCode);
        let regionFound = this.regions?.find(
            (region: any) => region.regionCode.toString() === regionCodeFormated
        )?.regionDescription;
        return regionFound;
    }

    checkCodePrice(code: string): string {
        switch (code) {
            case 'H':
                return 'Energía Cargos';

            case 'E':
                return 'Energía TD';

            case 'T':
                return 'Energía TDA';

            case 'X':
                return 'Excesos';

            case 'Z':
                return 'Exceso sin Kp';

            case 'C':
                return 'Pago por capacidad';

            case 'G':
                return 'Potencia cargos';

            case 'P':
                return 'Potencia TD';

            case 'R':
                return 'Pérdidas reguladas';
            default:
                return '';
        }
    }

    checkState(state: number | string, column?: any): string {
        if (column.closedState === '1') {
            return ClosedStatusFilter.ACTIVE;
        } else if (column.closedState === '2') {
            return ClosedStatusFilter.NOT_ACTIVE;
        }
        return '';
    }

    checkStateCoberturas(state: number | string, column?: any): string {
        switch (column.status) {
            case CoberturasContratosStatus.PRE_CONTRATO:
                return ClosedStatus.PRE_CONTRATO;
            case CoberturasContratosStatus.PENDING:
                return ClosedStatus.PENDING;
            case CoberturasContratosStatus.FORMALIZED:
                return ClosedStatus.FORMALIZED;
            case CoberturasContratosStatus.CANCELED_REJECTED:
                return ClosedStatus.CANCELED_REJECTED;
            case CoberturasContratosStatus.COMPLETED:
                return ClosedStatus.COMPLETED;
            default:
                return '';
        }
    }

    checkAutonomiesCodes(autonomiesFromBack: AutonomiesBackResponse) {
        let autonomies: object[] = [];
        autonomiesFromBack.autonomies.forEach((autonomy) => {
            switch (autonomy.code) {
                case AutonomyCodes.CASTILLA_LA_MANCHA:
                    autonomies.push({ name: 'Castilla-La Mancha' });
                    break;
                case AutonomyCodes.ARAGON:
                    autonomies.push({ name: 'Aragón' });
                    break;
                case AutonomyCodes.MADRID:
                    autonomies.push({ name: 'Madrid' });
                    break;
                case AutonomyCodes.CASTILLA_LEON:
                    autonomies.push({ name: 'Castilla y León' });
                    break;
                case AutonomyCodes.CATALUÑA:
                    autonomies.push({ name: 'Cataluña' });
                    break;
                default:
                    break;
            }
        });
        return autonomies;
    }

    //>>>>>>>>>>>>>FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS
    checkvalue(dataHeaders2: any) {
        this.dataHeaders_valueSelect = dataHeaders2;
        this.columns = [];
        for (let i = 0; i < dataHeaders2.length; i++) {
            let columnId;
            columnId = dataHeaders2[i].id;
            this.columns.push(columnId);
        }
    }

    botonClick() {
        if (this.element) {
            //Mostrar-ocultar Etiqueta Span "Columnas"
            this.element = false;
        } else {
            this.element = true;
        }
        if (!this.ocultar) {
            //Mostrar-ocultar select de columnas
            this.ocultar = true;
        } else {
            this.ocultar = false;
        }
    }

    filterDataHeader() {
        if (this.dataHeaders2 != this.dataHeaders) {
            this.dataHeaders2 = this.dataHeaders;
            this.dataHeaders_valueSelect = [];
            this.dataHeaderChange = true;
        }
        if (this.dataHeaderChange === true) {
            for (let i = 0; i < this.dataHeaders?.length; i++) {
                if (this.dataHeaders[i].isVisible === true! || this.dataHeaders[i].isVisible == undefined) {
                    this.dataHeaders_valueSelect.push(this.dataHeaders[i]);
                }
            }
            this.columns = [];
            for (let i = 0; i < this.dataHeaders_valueSelect.length; i++) {
                let columnId;
                columnId = this.dataHeaders_valueSelect[i].id;
                this.columns.push(columnId);
            }
        }
        this.dataHeaderChange = false;
        return this.dataHeaders;
    }
    //<<<<<<<<<<<<FUNCIONES PARA MOSTRAR/OCULTAR COLUMNAS

    codeConverterPR(code: string) {
        let codigo = '';
        switch (code) {
            case 'Energía cargos':
                code = 'H';
                break;
            case 'Energía TD':
                code = 'E';
                break;
            case 'Energía TDA':
                code = 'T';
                break;
            case 'Excesos':
                code = 'X';
                break;
            case 'Exceso sin Kp':
                code = 'Z';
                break;
            case 'Pago por capacidad':
                code = 'C';
                break;
            case 'Potencia cargos':
                code = 'G';
                break;
            case 'Potencia TD':
                code = 'P';
                break;
            case 'Pérdidas reguladas':
                code = 'R';
                break;
            default:
                code = '';
                break;
        }
        codigo = code;
        return codigo;
    }

    protected isLiquidationType(element: any, id: string): boolean {
        return element.liqType && id === 'liqType' && this.maestroService.selectId === MaestrosCRM.CONCEPTOS_CONTRATOS;
    }

    protected getLiquidationLabel(element: any): string {
        if (element.liqType === '1') {
            return 'Medida horaria';
        } else if (element.liqType === '2') {
            return 'Factura ATR';
        }
        return '';
    }

    returnColumnReeProfileName(name: string) {
        return this.maestroService.changeReeProfileName(name);
    }

    protected transformDate(date: any, largeFormat: boolean): string {
        if (!date) {
            return '-';
        }
        if (date.includes('/')) {
            date = date.replaceAll('/', '-');
        }
        const datePipe = new DatePipe('en-US');
        const dates = new Date(date);
        let formatDate;
        if (isNaN(dates.getTime())) {
            formatDate = '-';
        } else {
            if (largeFormat) {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'} HH:mm:ss`
                );
            } else {
                formatDate = datePipe.transform(
                    dates,
                    `${this._headerTitleService.currentLang !== 'en' ? 'dd/MM/yyyy' : 'yyyy/MM/dd'}`
                );
            }
        }
        return formatDate ? formatDate : date;
    }

    protected getFestiveTypeMapped(value: string): string {
        if (this._headerTitleService.currentLang === 'es') {
            switch (value) {
                case 'Autonomicos':
                    return 'Autonómicos';
                case 'Electricos':
                    return 'Eléctricos';
                default:
                    return value;
            }
        } else {
            return value;
        }
    }

    protected transformMonthNumberToText(month: number): string {
        return this._translateService.instant(Months[month]);
    }

    protected shouldDisplayColumn(columnId: string, element: any): boolean {
        const excludedColumns = [
            'variableType',
            'regionCode',
            'status',
            'formulaInEnergy',
            'formulaInEnergy',
            'formulaOutEnergy',
            'formulaReactEnergyQ1',
            'formulaReactEnergyQ2',
            'formulaReactEnergyQ3',
            'formulaReactEnergyQ4',
            'contractCode.status',
            'contract.status',
            'claims',
            'objections',
            'dateInit',
            'dateEnd',
            'creationDate',
            'updateDate',
            'festiveDate',
            'festiveType',
            'initialDate',
            'endDate',
            'date',
            'validityDateInit',
            'validityDateEnd',
            'initValidityDate',
            'finalValidityDate',
            'initDate',
            'initDateAtr',
            'endDateAtr',
            'hedgeDate',
            'finalDate',
            'initEffectiveDate',
            'finalEffectiveDate',
            'beginDate',
            'closedDate',
            'vigorDate',
            'regDateMP',
            'commercialRequest',
            'month',
            'cecoerActiveHub',
        ];
        const isExcluded = excludedColumns.includes(columnId);
        const isSpecialCase1 = columnId === 'name' && this.maestroService.selectId === MaestrosAWS.PERFILES_REE;
        const isSpecialCase2 = columnId === 'code' && this.maestroService.selectId === MaestrosAWS.PRECIOS_REGULADOS;
        return !isExcluded && !isSpecialCase1 && !isSpecialCase2 && !this.isLiquidationType(element, columnId);
    }

    protected getUnits(element: any, columnId: string): string {
        const unitMappings: any = {
            H: '€/MWh', // Energía Cargos
            E: '€/MWh', // Energía TD
            T: '€/MWh', // Energía TDA
            Z: '€/kW', // Exceso sin Kp
            X: '€/kW', // Excesos
            G: '€/kWaño', // Potencia cargos
            P: '€/kWaño', // Potencia TD
            R: '€/kWh', // Pérdidas reguladas
            C: '€/kWh', // Pago por capacidad
        };
        return unitMappings[element.code] || '';
    }
}
