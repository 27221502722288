import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { IColumnsTable } from 'src/app/shared/models/columns-table.model';
import { SortingObject } from 'src/app/shared/models/files.interface';
import { PaginationObject } from 'src/app/shared/models/paginationObject.interface';
import { MaestroService } from '../services/maestro.service';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { combineLatest } from 'rxjs';
import { MaestrosAWS, KeysMaestro, PaginationIDs, SortBy, PerfilesREE } from '../../../shared/enums/maestros';
import { LoginService } from 'src/app/core/services/login.service';
import { HeaderTitleService } from '../../../services/header-title.service';
@Component({
    selector: 'app-maestro-aws-page',
    templateUrl: './maestro-aws-page.component.html',
    styleUrls: ['./maestro-aws-page.component.scss'],
})
export class MaestroAWSPageComponent implements OnInit, OnChanges {
    dataBusiness: any;
    dataHeaders: IColumnsTable[];
    dataPagination: PaginationObject;
    dataSorting: SortingObject;
    columns: any;

    regions?: any[] = [];

    totalRegisters: number;
    pageSize: number;
    totalPages: number;
    numPage: number;

    permissions: any;
    headerEdit: IColumnsTable;
    headerDelete: IColumnsTable;
    filtersTable: any[]; // nombre de la tabla seleccionada en el concepto
    filters: any;
    idFilters: any;

    sortIdFilter: any;
    sortFilter: any;
    sortDirection: any;
    sortParameter: any;
    sortId: any;
    headers: any;
    pageIndex: any;
    sortDir: any;
    sortActive: any;
    copyFilterRate: any = [];
    isZoneSelected: boolean | undefined = undefined;
    constructor(
        private _maestroService: MaestroService,
        private globalService: GlobalService,
        private _loginService: LoginService,
        private _http: HttpClient,
        private _headerTitleService: HeaderTitleService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {}

    ngOnInit(): void {
        this._headerTitleService.currentHeaderTranslationCode = 'AWS_MASTER';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
    }

    transformHeaders() {
        this.columns = this.dataHeaders.map((col) => col.id);
    }

    /**
     * Método para formatear el parámetro de busqueda
     * @param idFiltersEvent id del filtro y subfiltros
     * @returns
     */
    formatSearch(idFiltersEvent: any): string {
        let params: string = '';
        let addParamFlag: boolean = false;
        if (idFiltersEvent[0] === 'liquidacionPotestativa') {
            //Liquidación Potestativa
            let searchDate = idFiltersEvent[1][0].selected.split('~');
            searchDate = 'date>=' + searchDate[0] + ',date<=' + searchDate[1];
            let unitProgram;
            if (idFiltersEvent[1][1].selected) {
                unitProgram = 'programUnitCode=' + idFiltersEvent[1][1].selected;
                params = searchDate + ',' + unitProgram;
                return params;
            } else {
                params = searchDate;
                return params;
            }
        }

        if (idFiltersEvent[0]) {
            idFiltersEvent[1].forEach((filter: any, index: number) => {
                addParamFlag = false;
                // Filtro Tipo de Festivo de FESTIVOS
                if (filter.selected && filter.key === KeysMaestro.TIPO_FESTIVO && !addParamFlag) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    filter.selected = this.removeEspecialCharacters(filter.selected);
                    params = params.concat(`${filter.key}like(${filter.selected})`);
                    addParamFlag = true;
                }

                // Filtro Fecha de FESTIVOS
                if (filter.selected && filter.key === KeysMaestro.FECHA_FESTIVO && !addParamFlag) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`festiveDate>=${splitedDates[0]},festiveDate<=${splitedDates[1]}`);
                    addParamFlag = true;
                }
                // Filtro Fecha Perfiles REE
                if (
                    filter.selected &&
                    filter.type === 'Date' &&
                    idFiltersEvent[0] === MaestrosAWS.PERFILES_REE &&
                    !addParamFlag
                ) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`date>=${splitedDates[0]},date<=${splitedDates[1]}`);
                    addParamFlag = true;
                }

                // Filtro Fecha de Unidad de Programación
                if (
                    filter.selected &&
                    filter.type === 'Date' &&
                    idFiltersEvent[0] === MaestrosAWS.UNIDAD_PROGRAMACION &&
                    !addParamFlag
                ) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`validityDateInit=(${splitedDates[0]};${splitedDates[1]})`);
                    addParamFlag = true;
                }
                if (
                    filter.selected &&
                    filter.type === 'Date' &&
                    idFiltersEvent[0] === MaestrosAWS.K_ESTIMADA &&
                    !addParamFlag
                ) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`date>=${splitedDates[0]},date<=${splitedDates[1]}`);
                    addParamFlag = true;
                }
                //Filtro para DATE Tarifarios
                if (
                    filter.selected &&
                    filter.type === 'Date' &&
                    idFiltersEvent[0] === MaestrosAWS.PERIODOS_TARIFARIOS &&
                    !addParamFlag
                ) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');

                    params = params.concat(`date=(${splitedDates[0]};${splitedDates[1]})`);
                    addParamFlag = true;
                }
                // Filtro Zona de Coeficiente de Pérdidas
                if (
                    filter.key == 'zoneGroup' &&
                    idFiltersEvent[0] === MaestrosAWS.COEFICIENTE_PERDIDAS &&
                    !addParamFlag
                ) {
                    filter.selected = this.removeEspecialCharacters(filter.selected);
                    filter.selected = filter.selected.toUpperCase();
                }
                // Filtro CUPS de Punto de Frontera
                if (filter.type === 'cups' && idFiltersEvent[0] === MaestrosAWS.PUNTO_FRONTERA && !addParamFlag) {
                    if (this._maestroService.validCups !== undefined) {
                        if (index !== 0 && params !== '') {
                            params = params.concat(',');
                        }
                        if (this._maestroService.validCups !== '') {
                            if (filter.key === KeysMaestro.BOUNDARY_POINT_CUPS) {
                                params = params.concat(`${filter.key}=(${this._maestroService.validCups})`);
                                addParamFlag = true;
                            }
                        }
                    }
                }
                // Filtros Input
                if (filter.selected && filter.type === 'Input' && !addParamFlag) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    params = params.concat(`${filter.key}like(${filter.selected})`);
                    addParamFlag = true;
                }

                if (
                    filter.selected &&
                    filter.type === 'Date' &&
                    filter.key !== KeysMaestro.FECHA_FESTIVO &&
                    idFiltersEvent[0] !== MaestrosAWS.PRECIOS_REGULADOS &&
                    idFiltersEvent[0] !== MaestrosAWS.CONTRATOS_FICTICIOS &&
                    !addParamFlag
                ) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    if (idFiltersEvent[0] === 'preciosIndexados') {
                        params = params.concat(`date=(${splitedDates[0]};${splitedDates[1]})`);
                    } else if (idFiltersEvent[0] === 'coeficientePerdidas') {
                        params = params.concat(`date>=${splitedDates[0]},date<=${splitedDates[1]}`);
                    } else if (idFiltersEvent[0] === MaestrosAWS.VARIABLES_CONTRATOS_FICTICIOS) {
                        params = params.concat(`initDate=(${splitedDates[0]};${splitedDates[1]})`);
                    } else if (idFiltersEvent[0] === MaestrosAWS.TARIFAS) {
                        params = params.concat(`DateInit=(${splitedDates[0]};${splitedDates.at(-1)})`);
                    } else {
                        params = params.concat(`dateInit>=${splitedDates[0]},dateEnd<=${splitedDates.at(-1)}`);
                    }
                    addParamFlag = true;
                }
                //Filtro Fecha Precios Regulados
                if (
                    filter.selected &&
                    filter.type === 'Date' &&
                    idFiltersEvent[0] === MaestrosAWS.PRECIOS_REGULADOS &&
                    !addParamFlag
                ) {
                    if (index !== 0 && params !== '') {
                        params = params.concat(',');
                    }
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`initialDate=(${splitedDates[0]};${splitedDates.at(-1)})`);
                    addParamFlag = true;
                } else {
                    if (filter.selected && filter.type !== 'Date' && !addParamFlag) {
                        if (
                            (idFiltersEvent[0] === MaestrosAWS.PERIODOS_TARIFARIOS ||
                                idFiltersEvent[0] === MaestrosAWS.COEFICIENTE_PERDIDAS) &&
                            (filter.key == 'rate' || filter.key == 'zoneGroup')
                        ) {
                            if (index !== 0 && params !== '') {
                                params = params.concat(',');
                            }
                            if (filter.key == 'rate' || filter.key == 'zoneGroup') {
                                if (filter.key == 'rate') {
                                    this.copyFilterRate = [...filter.selected];
                                    params = params.concat(`${filter.tipo}=${filter.selected}`);
                                    addParamFlag = true;
                                } else {
                                    if (
                                        idFiltersEvent[0] === MaestrosAWS.COEFICIENTE_PERDIDAS &&
                                        filter.key === 'zoneGroup'
                                    ) {
                                        params = params.concat(`geoZone=${filter.selected}`);
                                    } else if (
                                        idFiltersEvent[0] === MaestrosAWS.PERIODOS_TARIFARIOS &&
                                        filter.key === 'zoneGroup'
                                    ) {
                                        const selectedRate: string = this.idFilters[1].find(
                                            (e: any) => e.key === 'rate'
                                        )?.selected;
                                        const selectedZone: string =
                                            filter.selected === 'Península' ? 'Peninsula' : filter.selected;
                                        let selectedZoneGroup: string = '';
                                        if (selectedZone === 'Ceuta' || selectedZone === 'Melilla') {
                                            selectedZoneGroup = 'Ceuta-Melilla';
                                        } else if (
                                            selectedZone === 'Canarias' ||
                                            selectedZone === 'Baleares' ||
                                            selectedZone === 'Península' ||
                                            selectedZone === 'Peninsula'
                                        ) {
                                            selectedZoneGroup = 'Peninsula-Islas';
                                        }
                                        if (!selectedRate) {
                                            params = params.concat(
                                                `zonelike(${selectedZone}),zoneGrouplike(${selectedZoneGroup})`
                                            );
                                        } else if (selectedRate === '2.0TD') {
                                            params = params.concat(`zoneGrouplike(${selectedZoneGroup})`);
                                        } else {
                                            params = params.concat(`zonelike(${selectedZone})`);
                                        }
                                    } else if (idFiltersEvent[0] !== MaestrosAWS.PERIODOS_TARIFARIOS) {
                                        filter.selected = this.removeEspecialCharacters(filter.selected);
                                        if (
                                            this.copyFilterRate[0] === '2' ||
                                            (this.copyFilterRate.length === 0 &&
                                                (filter.selected === 'Ceuta-Melilla' ||
                                                    filter.selected === 'Peninsula-Islas'))
                                        ) {
                                            params = params.concat(`zoneGrouplike(${filter.selected})`);
                                            addParamFlag = true;
                                        } else if (
                                            this.copyFilterRate[0] === undefined &&
                                            (filter.selected != 'Ceuta-Melilla' || filter.selected != 'Peninsula-Islas')
                                        ) {
                                            params = params.concat(`zonelike(${filter.selected})`);
                                            addParamFlag = true;
                                        } else {
                                            params = params.concat(`zonelike(${filter.selected})`);
                                            addParamFlag = true;
                                        }
                                    }
                                }
                            }
                        } else if (
                            idFiltersEvent[0] === MaestrosAWS.PERFILES_REE &&
                            filter.key === PerfilesREE.KEY_NAME &&
                            !addParamFlag
                        ) {
                            if (filter.displayConditions[0].split('=')[1] === 'REE') {
                                filter.selected = filter.selected.toUpperCase();
                            }
                            if (index !== 0 && params !== '') {
                                params = params.concat(',');
                            }
                            if (
                                filter.selected !== PerfilesREE.INICIAL &&
                                filter.selected !== PerfilesREE.FINAL &&
                                filter.displayConditions[0].split('=')[1] !== 'REE'
                            ) {
                                filter.selected = this.removeEspecialCharacters(filter.selected);
                            }
                            params = params.concat(`name=${filter.selected}`);
                            addParamFlag = true;
                        }
                        //Filtro para TIPO TERMINO de PRECIOS REGULADOS:
                        else if (
                            filter.selected &&
                            idFiltersEvent[0] === MaestrosAWS.PRECIOS_REGULADOS &&
                            filter.key === KeysMaestro.PRECIOS_REGULADOS
                        ) {
                            if (index !== 0 && params !== '') {
                                params = params.concat(',');
                            }
                            filter.data.forEach((element: any) => {
                                if (element.name === filter.selected) {
                                    params = params.concat(`${filter.key}=${element.code}`);
                                    addParamFlag = true;
                                }
                            });
                        } else {
                            if (index !== 0 && params !== '') {
                                params = params.concat(',');
                            }
                            params = params.concat(`${filter.key}=${filter.selected}`);
                            addParamFlag = true;
                        }
                    }
                }
                // Filtro fecha adaptado para MaestrosAWS/contratosFicticios
                if (filter.type === 'Date' && idFiltersEvent[0] === MaestrosAWS.CONTRATOS_FICTICIOS) {
                    let splitedDates = idFiltersEvent[1][0].selected.split('~');
                    params = params.concat(`initEffectiveDate=(${splitedDates[0]};${splitedDates.at(-1)})`);
                    addParamFlag = true;
                }
            });
        }
        if (idFiltersEvent[0] === MaestrosAWS.CONTRATOS_FICTICIOS) {
            if (idFiltersEvent[1][1].selected) {
                params = params.replace('programUnitt', 'programUnitCode');
                return params;
            }
        } else if (idFiltersEvent[0] === MaestrosAWS.PERIODOS_TARIFARIOS) {
            this.isZoneSelected = params.includes('zoneGroup');
        }
        return params;
    }

    private getInfoTabla(idFilters: any, filter: boolean, sortParameter?: string) {
        this.permissions = this.globalService.transformPermissions(idFilters[0]);
        this.idFilters = idFilters;
        this.sortIdFilter = idFilters;
        this.sortFilter = filter;
        this.headers = this._loginService.getHeaderSession();
        if (!this.sortDirection) {
            this.sortDirection = 'asc';
        }
        switch (idFilters[0]) {
            case MaestrosAWS.TARIFAS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getFeesTable(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_tarifas', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.FESTIVOS:
                this.chargeFiltersInTable();
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaFestivos(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_festivos', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.PRECIOS_REGULADOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    this.clearTable();
                    combineLatest([
                        this._maestroService.getTablaPrecios(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_preciosRegulados', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.PRECIOS_INDEXADOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaPreciosIndexados(
                            1,
                            10,
                            SortBy.PRECIOS_INDEXADOS,
                            'asc',
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_preciosIndexados', this.idFilters[0]);
                    });
                }
                break;
            case MaestrosAWS.PRECIOS_MENSUALES:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaPreciosMensuales(
                            1,
                            10,
                            SortBy.PRECIOS_MENSUALES,
                            'asc',
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_preciosMensuales', this.idFilters[0]);
                    });
                }
                break;
            case MaestrosAWS.PARAMETROS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaParametros(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_parametros', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.DISTRIBUIDOR:
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getDistributorsTable(1, 10, this.sortParameter, this.sortDirection),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_distribuidor', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.PRELACION:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaPrelacion(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_prelacion', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.PERFILES_REE:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaPerfiles(
                            1,
                            10,
                            SortBy.PERFILES_REE,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res: any) => {
                        if (res[0].data) {
                            res[0].data = this._sortDataByDateHourRateName(res[0].data);
                        }
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_perfilesREE', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;

            case MaestrosAWS.PERIODOS_TARIFARIOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaPeriodosTarifarios(
                            -1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this._maestroService.searchParam,
                            this.isZoneSelected !== true
                        ),
                    ]).subscribe((res: any) => {
                        res = this._filterResponseToZoneGroups(res[0], { number: 1, size: 10 });
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_periodosTarifarios', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.UNIDAD_PROGRAMACION:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaUnidadProgramacion(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_unidadProgramacion', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.K_ESTIMADA:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablakEstimada(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_kEstimada', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.PUNTO_FRONTERA:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getBoundaryPoints(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_puntoFrontera', this.idFilters[0]); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.COEFICIENTE_PERDIDAS:
                this._maestroService.searchParam = this.formatSearch(idFilters);

                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaCoeficientePerdidas(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(
                            res,
                            JSON.parse(this.headers),
                            'maestro_coeficientePerdidas',
                            this.idFilters[0]
                        );
                    });
                }
                break;

            case MaestrosAWS.LIQUIDACION_POTESTATIVA:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getTablaLiquidacionPotestativa(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res: any) => {
                        if (res.data) {
                            res.data = this._sortDataByDateHourProgramUnit(res.data);
                        }
                        this.buildTable(
                            res,
                            JSON.parse(this.headers),
                            'maestro_liquidacionPotestativa',
                            this.idFilters[0]
                        ); //Mandamos res(datos), JSON.parse(this.headers) ->cabeceras(headers) , 6 ->equivale a la posicion de "distribuidor"
                    });
                }
                break;
            case MaestrosAWS.CONTRATOS_FICTICIOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getContratosFicticiosTable(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(res, JSON.parse(this.headers), 'maestro_contratosFicticios', this.idFilters[0]);
                    });
                }
                break;
            case MaestrosAWS.VARIABLES_CONTRATOS_FICTICIOS:
                this._maestroService.searchParam = this.formatSearch(idFilters);
                if (this.headers != null) {
                    combineLatest([
                        this._maestroService.getVarContratosFicticiosTable(
                            1,
                            10,
                            this.sortParameter,
                            this.sortDirection,
                            this.formatSearch(idFilters)
                        ),
                    ]).subscribe((res) => {
                        this.buildTable(
                            res,
                            JSON.parse(this.headers),
                            'maestro_variablesContratosFicticios',
                            this.idFilters[0]
                        );
                    });
                }
                break;
            default:
                console.log('No se ha encontrado la tabla');
                break;
        }
        this.sortParameter = undefined;
    }

    private _filterResponseToZoneGroups(res: any, newParams: any): any {
        // Sets data according to the request parameters
        if (res.data && res.data.length > 0) {
            // Filters data by zone selected if it's been selected
            if (this.isZoneSelected) {
                const data: any = this.idFilters && this.idFilters[1] ? this.idFilters[1] : this.filtersTable[1];
                let zone: string = data.find((e: any) => e.key === 'zoneGroup')?.selected;
                zone = zone === 'Península' ? 'Peninsula' : zone;
                let zoneGroup: string = 'Peninsula-Islas';
                if(zone.includes('Ceuta') || zone.includes('Melilla')){
                    zoneGroup = 'Ceuta-Melilla';
                }
                res.data = res.data.filter((e: any) => e.zoneGroup === zoneGroup);
                // Lógica para mapear columna 'zoneGroup' en periodos tarifarios
                res.data = res.data.map((e: any) => {
                    e.zoneGroup = zone;
                    return e;
                });
            }
            // Deletes duplicate data
            res.data = [...new Set(res.data)];
            // Sorts data by rate, date, hour and zone
            const zoneOrder: any = { Peninsula: 1, Baleares: 2, Canarias: 3, Ceuta: 4, Melilla: 5 };
            res.data = res.data.sort((a: any, b: any) => {
                if (a.rate !== b.rate) {
                    return a.rate.localeCompare(b.rate);
                }
                if (a.date !== b.date) {
                    return a.date.localeCompare(b.date);
                }
                if (a.hour !== b.hour) {
                    return a.hour - b.hour;
                }
                return zoneOrder[a.zone] - zoneOrder[b.zone];
            });
            // Sets pagination data
            res.page.number = newParams.number;
            res.page.size = newParams.size;
            res.page.totalElements = res.data.length;
            res.page.totalPages = Math.ceil(res.page.totalElements / newParams.size);
            const lastRegisterPosition: number = newParams.number * newParams.size - newParams.size;
            res.data = res.data.slice(lastRegisterPosition, lastRegisterPosition + newParams.size);
        }
        return [res];
    }

    clearTable($event?: any) {
        this.dataBusiness = undefined;
    }

    receiveIdFilters($event: any) {
        this.getInfoTablaByFilterSort($event);
    }

    receiveIdSelect($event: any) {
        let filter: boolean = false;
        for (let index = 0; index < $event[1].length; index++) {
            if ($event[1][index].selected) {
                filter = true;
            }
        }
        this.filtersTable = $event;
        this.getInfoTabla($event, filter);
    }

    reset() {}

    getInfoTablaByFilterSort(s: any) {}

    refreshAfterEdit() {
        this.getInfoTabla(this.sortIdFilter, this.sortFilter, this.sortParameter);
    }

    sort($event: any) {
        this.sortDirection = $event.sort.direction;
        this.sortParameter = $event.sort.active;
        this.sortId = $event.id;
        if (environment.isMockActive) {
            this._maestroService.getTablaPreciosSort($event).subscribe((res) => {
                this.dataBusiness = res.preciosRegulados;
                this.dataHeaders = res.headers;
                this.totalRegisters = res.totalRegisters;
                this.transformHeaders();
            });
        }
        this.getInfoTabla(this.sortIdFilter, this.sortFilter, this.sortParameter);
    }

    pagination(event: any) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;

        // Ordenar por ASC o DESC
        if (!this.sortDir) {
            this.sortDir = 'asc';
        }

        event.pageIndex = event.pageIndex + 1;
        // Filtrar por ...
        switch (this.idFilters[0]) {
            case PaginationIDs.TARIFA:
                this.sortActive = SortBy.CODE_RATE;
                break;
            case PaginationIDs.FESTIVOS:
                this.sortActive = SortBy.FESTIVE;
                break;
            case PaginationIDs.DISTRIBUIDOR:
                this.sortActive = SortBy.CODE;
                break;
            case PaginationIDs.UNIDAD_PROGRAMACION:
                this.sortActive = SortBy.UNIT_PROGRAM_CODE;
                break;
            case PaginationIDs.PERFILES_REE:
                this.sortActive = SortBy.PERFILES_REE;
                break;
            case PaginationIDs.PRELACION:
                this.sortActive = SortBy.PRELACION;
                break;
            case PaginationIDs.CONTRATOS_FICTICIOS:
                this.sortActive = SortBy.CONTRATOS_FICTICIOS;
                break;
            case PaginationIDs.PERIODOS_TARIFARIOS:
                this.sortActive = SortBy.PERIODOS_TARIFARIOS;
                break;
            case PaginationIDs.PRECIOS_REGULADOS:
                this.sortActive = SortBy.PRECIOS_REGULADOS;
                break;
            case PaginationIDs.PRECIOS_INDEXADOS:
                this.sortActive = SortBy.PRECIOS_INDEXADOS;
                break;
            case PaginationIDs.PRECIOS_MENSUALES:
                this.sortActive = SortBy.PRECIOS_MENSUALES;
                break;
            case PaginationIDs.PARAMETROS:
                this.sortActive = SortBy.CODE;
                break;
            case PaginationIDs.COEFICIENTE_PERDIDAS:
                this.sortActive = SortBy.COEFICIENTE_PERDIDAS;
                break;
            case PaginationIDs.K_ESTIMADA:
                this.sortActive = SortBy.K_ESTIMADA;
                break;
            case PaginationIDs.LIQUIDACION_POTESTATIVA:
                this.sortActive = SortBy.LIQUIDACION_POTESTATIVA;
                break;
            case PaginationIDs.VARIABLES_CONTRATOS_FICTICIOS:
                this.sortActive = SortBy.VARIABLES_CONTRATOS_FICTICIOS;
                break;
            case PaginationIDs.PUNTO_FRONTERA:
                this.sortActive = SortBy.BOUNDARY_POINT_CODE;
                break;
        }

        if (this.idFilters.length > 0) {
            switch (this.idFilters[0]) {
                case PaginationIDs.DISTRIBUIDOR:
                    this._maestroService
                        .getDistributorsTable(event.pageIndex, event.pageSize, this.sortActive, this.sortDir)
                        .subscribe((res: any) => {
                            this.dataBusiness = res.data;
                            this.totalRegisters = res.page.totalElements;
                            this.numPage = res.page.number;
                            this.pageSize = res.page.size;
                            this.totalPages = res.page.totalPages;
                        });
                    break;
                case PaginationIDs.TARIFA:
                    this._maestroService
                        .getFeesTable(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                        .subscribe((res: any) => {
                            this.dataBusiness = res.data;
                            this.totalRegisters = res.page.totalElements;
                            this.numPage = res.page.number;
                            this.pageSize = res.page.size;
                            this.totalPages = res.page.totalPages;
                        });
                    break;
                case PaginationIDs.LIQUIDACION_POTESTATIVA:
                    this._maestroService
                        .getTablaLiquidacionPotestativa(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                        .subscribe((res: any) => {
                            if (res.data) {
                                res.data = this._sortDataByDateHourProgramUnit(res.data);
                            }
                            this.dataBusiness = res.data;
                            this.totalRegisters = res.page.totalElements;
                            this.numPage = res.page.number;
                            this.pageSize = res.page.size;
                            this.totalPages = res.page.totalPages;
                        });
                    break;
                case PaginationIDs.UNIDAD_PROGRAMACION:
                    this.buildPagination(
                        this._maestroService.getTablaUnidadProgramacion(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.PERFILES_REE:
                    this.buildPagination(
                        this._maestroService.getTablaPerfiles(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        ),
                        'perfilesREE'
                    );
                    break;
                case PaginationIDs.PRELACION:
                    this.buildPagination(
                        this._maestroService.getTablaPrelacion(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.PERIODOS_TARIFARIOS:
                    this.buildPagination(
                        this._maestroService.getTablaPeriodosTarifarios(
                            this.isZoneSelected !== true ? 1 : event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam,
                            this.isZoneSelected !== true
                        ),
                        'periodosTarifarios'
                    );
                    break;
                case PaginationIDs.PRECIOS_REGULADOS:
                    this.buildPagination(
                        this._maestroService.getTablaPrecios(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.PRECIOS_INDEXADOS:
                    this.buildPagination(
                        this._maestroService.getTablaPreciosIndexados(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.PRECIOS_MENSUALES:
                    this.buildPagination(
                        this._maestroService.getTablaPreciosMensuales(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.PUNTO_FRONTERA:
                    this._maestroService
                        .getBoundaryPoints(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                        .subscribe((res: any) => {
                            this.dataBusiness = res.data;
                            this.totalRegisters = res.page.totalElements;
                            this.numPage = res.page.number;
                            this.pageSize = res.page.size;
                            this.totalPages = res.page.totalPages;
                        });
                    break;
                case PaginationIDs.PARAMETROS:
                    this.buildPagination(
                        this._maestroService.getTablaParametros(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.FESTIVOS:
                    this.buildPagination(
                        this._maestroService.getTablaFestivos(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.COEFICIENTE_PERDIDAS:
                    this.buildPagination(
                        this._maestroService.getTablaCoeficientePerdidas(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.K_ESTIMADA:
                    this.buildPagination(
                        this._maestroService.getTablakEstimada(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.CONTRATOS_FICTICIOS:
                    this.buildPagination(
                        this._maestroService.getContratosFicticiosTable(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
                case PaginationIDs.VARIABLES_CONTRATOS_FICTICIOS:
                    this.buildPagination(
                        this._maestroService.getVarContratosFicticiosTable(
                            event.pageIndex,
                            event.pageSize,
                            this.sortActive,
                            this.sortDir,
                            this._maestroService.searchParam
                        )
                    );
                    break;
            }
        }
    }

    chargeFiltersInTable() {
        if (this._maestroService.selectId === MaestrosAWS.FESTIVOS) {
            this._maestroService.getRegions().subscribe((autonomies: any) => {
                autonomies.data.forEach((element: any) => {
                    this.regions?.push({
                        regionCode: element.regionCode,
                        regionDescription: element.regionDescription,
                    });
                });
            });
        }
    }

    buildTable(res: any, header: any, nameHeaders: string, idFilter?: string): void {
        this.permissions = this.globalService.transformPermissions(idFilter!);
        this.dataBusiness = res[0].data;

        // Lógica de guardar los CUPS filtrados en el maestroService para luego usarlos en Punto de Frontera.
        if (idFilter === MaestrosAWS.PUNTO_FRONTERA) {
            let dataBusinessCopy = this.dataBusiness;
            this._maestroService.boundaryPoints = dataBusinessCopy
                ?.map((data: any) => data.boundaryPointCode)
                .join(',')
                .split(',');
            this._maestroService.boundaryPoints = [...new Set(this._maestroService.boundaryPoints)];
        }

        // Lógica para mapear columna 'zoneGroup' en periodos tarifarios
        if (idFilter === MaestrosAWS.PERIODOS_TARIFARIOS) {
            const data: any = this.idFilters && this.idFilters[1] ? this.idFilters[1] : this.filtersTable[1];
            const zone: string = data.find((e: any) => e.key === 'zoneGroup')?.selected;
            if (zone) {
                this.dataBusiness = this.dataBusiness.map((e: any) => {
                    e.zoneGroup = zone;
                    return e;
                });
            }
        }

        header.data.forEach((element: any) => {
            if (element.name === nameHeaders && element.headers[0].id != 'fechaFestiva') {
                this.dataHeaders = element.headers;
            }
        });

        this.totalRegisters = res[0].page.totalElements;
        this.numPage = res[0].page.number;
        this.pageSize = res[0].page.size;
        this.totalPages = res[0].page.totalPages;
        this.transformHeaders();
    }

    buildPagination(getTablaX: any, page: string = ''): void {
        getTablaX.subscribe((res: any) => {
            if (page === 'periodosTarifarios') {
                res = this._filterResponseToZoneGroups(res, { number: this.pageIndex + 1, size: this.pageSize })[0];
            } else if (page === 'perfilesREE') {
                if (res.data) {
                    res.data = this._sortDataByDateHourRateName(res.data);
                }
            }
            this.dataBusiness = res.data;
            this.totalRegisters = res.page.totalElements;
            this.numPage = res.page.number;
            this.pageSize = res.page.size;
            this.totalPages = res.page.totalPages;
        });
    }

    removeEspecialCharacters(texto: string) {
        return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    private _sortDataByDateHourProgramUnit(data: any) {
        return data.sort((a: any, b: any) => {
            if (a.date !== b.date) {
                return a.date.localeCompare(b.date);
            }
            if (a.hour !== b.hour) {
                return a.hour - b.hour;
            }
            return a.programUnitCode - b.programUnitCode;
        });
    }

    private _sortDataByDateHourRateName(data: any) {
        return data.sort((a: any, b: any) => {
            if (a.date !== b.date) {
                return a.date.localeCompare(b.date);
            }
            if (a.hour !== b.hour) {
                return a.hour - b.hour;
            }
            if (a.rate !== b.rate) {
                return a.rate.localeCompare(b.rate);
            }
            const nameOrder: any = { INICIAL: 1, FINAL: 2 };
            if (a.name && b.name && nameOrder[a.name] && nameOrder[b.name]) {
                return nameOrder[a.name] - nameOrder[b.name];
            }
        });
    }
}
