import { Component, OnInit } from '@angular/core';
import { Files, FilesToUpload, SistemaCargasOption } from 'src/app/shared/interfaces/cargas.interface';
import { CargasServiceService } from '../../services/cargas-service.service';
import { LoginService } from 'src/app/core/services/login.service';
import { HeaderTitleService } from '../../../../services/header-title.service';
import { SistemasCargasEnum } from 'src/app/shared/enums/cargas';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
@Component({
    selector: 'app-select-cargas',
    templateUrl: './select-cargas.component.html',
    styleUrls: ['./select-cargas.component.scss'],
})
export class SelectCargasComponent implements OnInit {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    displayedColumns: string[][] = [['Sistemas'], ['Archivos', 'edit', 'delete']];

    userRoles: string[] = [];

    fileName: string;
    selectedFile: Files;
    fileToProcess: Files;
    selectSystems: SistemaCargasOption[] = [
        {
            id: 1,
            name: SistemasCargasEnum.MEDIDA,
            translation: 'MEASURES',
        },
        {
            id: 2,
            name: SistemasCargasEnum.CIERRE,
            translation: 'CLOUSURES',
        },
        {
            id: 3,
            name: SistemasCargasEnum.PREVISION,
            translation: 'FORECAST',
        },
        {
            id: 4,
            name: SistemasCargasEnum.COMUN,
            translation: 'COMMON',
        },
        {
            id: 5,
            name: SistemasCargasEnum.CONCILIACION,
            translation: 'CONCILIATION',
        },
    ];
    filesList: Files[] = [];
    filteredFilesList: Files[] = [];
    filesToUploadList: Files[] = [];
    filesToUploadListCopy: Files[] = [];
    filesToUploadIds: string[] = [];
    filesToUploadTotal: number = 0;
    uploadedFilesTotal: number = 0;
    downloadFileUrl: string = '';

    isFileTypeSelected: boolean = false;
    isDownloadDisabled: boolean = true;
    isUploadDisabled: boolean = true;

    uploadProcess: boolean = false;
    existFilesToUpload: boolean = false;
    isOnlyQueryRole: boolean = false;
    isRequestPending: boolean = false;
    isAddingFiles: boolean = false;

    constructor(
        private cargasServiceService: CargasServiceService,
        private _loginService: LoginService,
        private _headerTitleService: HeaderTitleService,
        private _translateService: TranslateService,
        private _snackBar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.isOnlyQueryRole = false;
        this._headerTitleService.currentHeaderTranslationCode = 'MANUAL_LOADS_TITLE';
        this._headerTitleService.updateHeader(this._headerTitleService.currentHeaderTranslationCode);
        this.cargasServiceService.getCargas().subscribe((filesResponse: Files[]) => {
            this.filesList = this._filterFilesByCurrentSystems(filesResponse);
            this.fileToProcess = new Files();
            this.userRoles = this._loginService.getUserActiveRoles()!.split(',');
            this.userRoles = this.userRoles.map((element: any) => element.trim());
            if (this.userRoles.length === 1 && this.userRoles[0] === 'CONSULTA') {
                this.isOnlyQueryRole = true;
            }
            this._populateTypesByUserRoles();
            this._populateSystemsByUserRoles();
        });
    }

    protected onSelectChangeTypes(selectedType: string) {
        if (selectedType === 'reset') {
            this._populateTypesByUserRoles();
        }
        if (selectedType === SistemasCargasEnum.MEDIDA) {
            this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.MEDIDA);
        }
        if (selectedType === SistemasCargasEnum.CIERRE) {
            this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.CIERRE);
        }
        if (selectedType === SistemasCargasEnum.PREVISION) {
            this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.PREVISION);
        }
        if (selectedType === SistemasCargasEnum.COMUN) {
            this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.COMUN);
        }
        if (selectedType === SistemasCargasEnum.CONCILIACION) {
            this.filteredFilesList = this.filesList.filter((file) => file.area === SistemasCargasEnum.CONCILIACION);
        }
        this.isFileTypeSelected = false;
        this.filteredFilesList.forEach((data) => (data.selected = false));
    }

    protected onClickSelectFileType(elementSelected: Files) {
        if (this.selectedFile === elementSelected && elementSelected.selected) {
            this.isFileTypeSelected = false;
            this.filteredFilesList.forEach((data) => (data.selected = false));
            if (elementSelected.file === 'MANUALCLOSE') {
                this.isDownloadDisabled = true;
            }
        } else {
            this.selectedFile = elementSelected;
            this.fileName = elementSelected.description;
            this.downloadFileUrl = elementSelected.fileTemplate;
            if (!elementSelected.selected) {
                if (!elementSelected.fileTemplate) {
                    this.isDownloadDisabled = true;
                } else {
                    this.isDownloadDisabled = false;
                }
                this.filteredFilesList.forEach((data) => (data.selected = false));
                elementSelected.selected = true;
                this.isUploadDisabled = false;
            } else {
                elementSelected.selected = false;
                this.isUploadDisabled = true;
            }
            this.isFileTypeSelected = true;
        }
    }

    protected isAddFileButtonDisabled(): boolean {
        if (!this.isFileTypeSelected || this.isRequestPending || this.isAddingFiles) {
            this.isUploadDisabled = true;
            return true;
        } else {
            this.isUploadDisabled = false;
            return false;
        }
    }

    protected getDownloadFileUrl(fileUrl: any) {
        this.cargasServiceService.getUrlDownload(fileUrl);
    }

    protected addFile(event: any) {
        this.isRequestPending = true;
        this.isAddingFiles = true;
        this.isUploadDisabled = true;

        // Para controlar que no se agreguen dos archivos con mismo nombre y mismo tipo de fichero
        if (event.target.files && event.target.files.length > 0) {
            const files = Array.from(event.target.files);
            files.forEach((file: any) => {
                if (
                    this.filesToUploadList.findIndex(
                        (fileData) =>
                            fileData.description === this.selectedFile.description &&
                            fileData.name === file.name &&
                            fileData.size === file.size
                    ) === -1
                ) {
                    let selectedFile = this._mapFileToUpload(file);
                    this.filesToUploadList = [...this.filesToUploadList, selectedFile];
                    this._setTotalFilesInfo();
                    this._sortFilesList();
                    this.existFilesToUpload = true;
                }
            });
        }
        this.isUploadDisabled = false;
        this.isAddingFiles = false;
        this.isRequestPending = false;
    }

    protected uploadFiles(fileToUploadName: any = undefined) {
        this.isRequestPending = true;
        const uploadFileForm = new FormData();
        let fileBinaries: File[] = [];
        let filesInfoList: FilesToUpload[] = [];
        let filesPendingToUploadList: any;
        this.filesToUploadListCopy = this.filesToUploadList;
        // IF INDIVIDUAL UPLOAD
        if (fileToUploadName !== undefined) {
            filesPendingToUploadList = this.filesToUploadListCopy.filter(
                (file: any) => file.name !== fileToUploadName.name || file.file !== fileToUploadName.file
            );
            this.filesToUploadListCopy = this.filesToUploadListCopy.filter(
                (file: any) => file.name === fileToUploadName.name && file.file === fileToUploadName.file
            );
        }
        this.filesToUploadListCopy = this.filesToUploadListCopy.map((file: any) => {
            if (file.status === 'NOT_UPLOADED') {
                file.status = 'UPLOADING';
            }
            return file;
        });
        try {
            this.filesToUploadListCopy.forEach((fileToUpload) => {
                if (
                    !fileToUpload.uploaded &&
                    !fileBinaries.includes(fileToUpload.content!) &&
                    filesInfoList.findIndex(
                        (fileInfo) =>
                            fileInfo.origin === fileToUpload.origin &&
                            fileInfo.file === fileToUpload.file &&
                            fileInfo.names.includes(fileToUpload.name!)
                    ) === -1
                ) {
                    fileBinaries.push(fileToUpload.content!);
                    const fileToUploadInfo = new FilesToUpload();
                    if (filesInfoList.length === 0) {
                        fileToUploadInfo.origin = fileToUpload.origin;
                        fileToUploadInfo.file = fileToUpload.file;
                        fileToUploadInfo.names = [fileToUpload.name!];
                        filesInfoList.push(fileToUploadInfo);
                    } else {
                        if (filesInfoList.findIndex((fileInfo) => fileInfo.origin === fileToUpload.origin) === -1) {
                            fileToUploadInfo.origin = fileToUpload.origin;
                            fileToUploadInfo.file = fileToUpload.file;
                            fileToUploadInfo.names = [fileToUpload.name!];
                            filesInfoList.push(fileToUploadInfo);
                        } else {
                            if (filesInfoList.findIndex((fileInfo) => fileInfo.file === fileToUpload.file) === -1) {
                                fileToUploadInfo.origin = fileToUpload.origin;
                                fileToUploadInfo.file = fileToUpload.file;
                                fileToUploadInfo.names = [fileToUpload.name!];
                                filesInfoList.push(fileToUploadInfo);
                            } else {
                                filesInfoList.forEach((fileInfo: FilesToUpload) => {
                                    if (
                                        fileInfo.origin === fileToUpload.origin &&
                                        fileInfo.file === fileToUpload.file
                                    ) {
                                        fileInfo.names = [...fileInfo.names, fileToUpload.name!];
                                    }
                                });
                            }
                        }
                    }
                }
            });
            if (fileBinaries.length > 0 && filesInfoList.length > 0) {
                fileBinaries.forEach((binary) => {
                    uploadFileForm.append('files', binary);
                });
                uploadFileForm.append('uploadDTO', JSON.stringify(filesInfoList));
                this.cargasServiceService.postFileProcess(uploadFileForm).subscribe(
                    (uploadFilesResponse) => {
                        this.isRequestPending = false;
                        uploadFilesResponse.forEach((uploadedFile: any) => {
                            this.filesToUploadListCopy.forEach((fileToUpload) => {
                                if (fileToUpload.name === uploadedFile.name && uploadedFile.status === 'OK') {
                                    fileToUpload.status = 'UPLOADED';
                                    fileToUpload.uploaded = true;
                                    document
                                        .getElementById(fileToUpload.name + fileToUpload.description)
                                        ?.classList.add('no-pointer-event');
                                }
                                if (uploadedFile.status === 'KO') {
                                    fileToUpload.uploadError = true;
                                    fileToUpload.status = 'UPLOAD_ERROR';
                                }
                            });
                        });
                        this._loadNotification('FILES_MANUAL_UPLOAD_SUCCESFUL');
                        if (fileToUploadName !== undefined) {
                            this.filesToUploadList = filesPendingToUploadList.concat(this.filesToUploadListCopy);
                        }
                        this.filesToUploadList = this.filesToUploadList.map((file: any) => {
                            if (file.status !== 'UPLOADED' && file.status !== 'UPLOAD_ERROR') {
                                file.status = 'NOT_UPLOADED';
                            }
                            return file;
                        });
                        this._setTotalFilesInfo();
                        this._sortFilesList();
                    },
                    (error: any) => {
                        this.isRequestPending = false;
                        if (fileToUploadName !== undefined) {
                            this.filesToUploadList = filesPendingToUploadList.concat(this.filesToUploadListCopy);
                        }
                        this.filesToUploadList = this.filesToUploadList.map((file: any) => {
                            if (file.status === 'UPLOADING') {
                                file.status = 'NOT_UPLOADED';
                            }
                            return file;
                        });
                    }
                );
            }
        } catch (error: any) {
            if (fileToUploadName !== undefined && filesPendingToUploadList) {
                this.filesToUploadList = filesPendingToUploadList.concat(this.filesToUploadListCopy);
            }
            this.filesToUploadList = this.filesToUploadList.map((file: any) => {
                if (file.status === 'UPLOADING') {
                    file.status = 'NOT_UPLOADED';
                }
                return file;
            });
            this._loadNotification('FILES_LOADING_UNSUCCESFUL');
            this.isRequestPending = false;
        }
    }

    protected deleteFile(fileToDelete: any) {
        this.filesToUploadList = this.filesToUploadList.filter(
            (file) => !(fileToDelete.name === file.name && file.description === fileToDelete.description)
        );
        if (this.filesToUploadList.length <= 0) {
            this.existFilesToUpload = false;
        }
        this._setTotalFilesInfo();
    }

    protected disableButtons(): void {
        this.isRequestPending = true;
        this.isUploadDisabled = true;
        this.isAddingFiles = true;
    }

    protected enableButtonsAfterAddingFiles(): void {
        if (!this.isAddingFiles) {
            this.isRequestPending = false;
            this.isUploadDisabled = false;
        }
    }

    protected isAnyFileToUpload(): boolean {
        return this.filesToUploadList.some((file: any) => file.status === 'NOT_UPLOADED');
    }

    private _setTotalFilesInfo(): void {
        this.filesToUploadTotal = this.filesToUploadList.length;
        this.uploadedFilesTotal = this.filesToUploadList.filter((file: any) => file.status === 'UPLOADED').length;
    }

    private _sortFilesList(): void {
        this.filesToUploadList = this.filesToUploadList.sort((a: any, b: any) =>
            a.status === b.status ? a.id - b.id : a.status === 'NOT_UPLOADED' ? -1 : 1
        );
    }

    private _filterFilesByCurrentSystems(filesResponse: Files[]): Files[] {
        return filesResponse.filter(
            (file) =>
                file.area === SistemasCargasEnum.COMUN ||
                file.area === SistemasCargasEnum.MEDIDA ||
                file.area === SistemasCargasEnum.CIERRE ||
                file.area === SistemasCargasEnum.PREVISION ||
                file.area === SistemasCargasEnum.CONCILIACION
        );
    }

    private _populateSystemsByUserRoles(): void {
        if (!this.userRoles.includes('ADMIN')) {
            if (!this.userRoles.includes('MEDIDA')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) => system.name !== SistemasCargasEnum.MEDIDA && system.name !== SistemasCargasEnum.CIERRE
                );
            }
            if (!this.userRoles.includes('PREVISION')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) => system.name !== SistemasCargasEnum.PREVISION
                );
            }
            if (!this.userRoles.includes('CONCILIACION')) {
                this.selectSystems = this.selectSystems.filter(
                    (system) => system.name !== SistemasCargasEnum.CONCILIACION
                );
            }
        }
    }

    private _populateTypesByUserRoles(): void {
        this.filteredFilesList = this.filesList;
        if (!this.userRoles.includes('ADMIN')) {
            if (!this.userRoles.includes('MEDIDA')) {
                this.filteredFilesList = this.filteredFilesList.filter(
                    (file) => file.area !== SistemasCargasEnum.MEDIDA && file.area !== SistemasCargasEnum.CIERRE
                );
            }
            if (!this.userRoles.includes('PREVISION')) {
                this.filteredFilesList = this.filteredFilesList.filter(
                    (file) => file.area !== SistemasCargasEnum.PREVISION
                );
            }
            if (!this.userRoles.includes('CONCILIACION')) {
                this.filteredFilesList = this.filteredFilesList.filter(
                    (file) => file.area !== SistemasCargasEnum.CONCILIACION
                );
            }
        }
    }

    private _mapFileToUpload(file: File): Files {
        let fileToUpload = new Files();
        fileToUpload.id = this.selectedFile.id;
        fileToUpload.area = this.selectedFile.area;
        fileToUpload.description = this.selectedFile.description;
        fileToUpload.file = this.selectedFile.file;
        fileToUpload.fileTemplate = this.selectedFile.fileTemplate;
        fileToUpload.origin = this.selectedFile.origin;
        fileToUpload.name = file.name;
        fileToUpload.size = file.size;
        fileToUpload.status = 'NOT_UPLOADED';
        fileToUpload.uploaded = false;
        fileToUpload.content = file;
        return fileToUpload;
    }

    private _loadNotification(literal: string, additionalMessage?: string) {
        this._translateService.get('PAGE.MODALS').subscribe((message) => {
            this._snackBar.open(
                `${message[literal]} ${additionalMessage ? additionalMessage : ''}`,
                message['ACTION_CLOSE'],
                {
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                }
            );
            setTimeout(() => {
                this._snackBar.dismiss();
            }, 6000);
        });
    }
}
